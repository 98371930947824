import classNames from 'classnames'
import { Badge } from 'components/Badge'
import { useAnalytics } from 'hooks/useAnalytics'
import React, { forwardRef, ReactNode } from 'react'
import { SpinnerCircularFixed } from 'spinners-react'
import { twMerge } from 'tailwind-merge'
import { blue, gray, red } from 'tailwindcss/colors'

export type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'white'
  | 'ghost'
  | 'outline'
  | 'danger-outline'
  | 'link'
  | 'muted-link'
  | 'fill'
  | 'light'
  | 'round'
  | 'round-indigo'
  | 'danger-outline'
  | 'danger-link'
  | 'unstyled'
export type ButtonProps = {
  id?: string
  block?: boolean
  children?: ReactNode
  className?: string
  disabled?: boolean
  icon?: any
  label?: string
  rightIcon?: any
  size?: ButtonSize
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  variant?: ButtonVariant
  wrap?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>
  onBlur?: React.FocusEventHandler<HTMLButtonElement>
}

const getSizeClasses = (size: ButtonSize, variant?: ButtonVariant) => {
  if (variant === 'fill' || variant === 'unstyled') {
    return ''
  }

  let base = ''
  let rounding = 'rounded-md'
  let textSize = 'text-base'

  switch (variant) {
    case 'round':
    case 'round-indigo':
      base = 'px-4 py-2'
      break
    case 'link':
    case 'danger-link':
    case 'muted-link':
      base = 'p-0'
      break
  }

  switch (size) {
    case 'xxs':
      rounding = 'rounded'
      textSize = 'text-xs'
      base ||= 'px-1.5 py-0.5'
      break
    case 'xs':
      rounding = 'rounded'
      textSize = 'text-xs'
      base ||= 'px-2.5 py-1.5'
      break
    case 'sm':
      textSize = 'text-sm'
      base ||= 'px-3 py-1.5 leading-4'
      break
    case 'lg':
      base ||= 'px-4 py-2.5'
      break
    case 'xl':
      base ||= 'px-6 py-3'
      break
    case 'md':
    default:
      base ||= 'px-4 py-2'
      textSize = 'text-sm'
      break
  }

  return `${base} ${textSize} ${rounding}`
}

const getVariantBg = (variant: ButtonVariant, loading?: boolean) => {
  switch (variant) {
    case 'primary':
      return 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-600'
    case 'danger':
      return 'bg-red-600 hover:bg-red-700 focus:ring-red-600'
    case 'secondary':
      return 'bg-blue-100 hover:bg-blue-200 dark:bg-blue-900 dark:bg-opacity-40'
    case 'ghost':
      return loading ? 'bg-white dark:transparent' : 'bg-transparent hover:bg-gray-100 dark:hover:bg-dark-500'
    case 'outline':
      return loading ? 'bg-white dark:transparent' : 'bg-transparent hover:bg-gray-100 dark:hover:bg-dark-500'
    case 'danger-outline':
      return 'bg-transparent hover:bg-red-50 dark:hover:bg-dark-500'
    case 'link':
    case 'muted-link':
    case 'danger-link':
      return 'bg-transparent hover:underline'
    case 'fill':
      return 'bg-transparent hover:bg-gray-50 dark:bg-dark-700 dark:hover:bg-dark-600'
    case 'round':
      return 'bg-transparent hover:bg-gray-100 dark:hover:bg-dark-500'
    case 'round-indigo':
      return 'bg-indigo-900 hover:bg-indigo-950'
    case 'light':
    case 'unstyled':
      return ''
    case 'white':
    default:
      return 'bg-background hover:bg-gray-50 dark:bg-dark-600 dark:hover:bg-dark-500'
  }
}

const getVariantClasses = (variant: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      return `border-transparent text-white ${getVariantBg(variant)}`
    case 'danger':
      return `border-transparent text-white ${getVariantBg(variant)}`
    case 'secondary':
      return `border-transparent text-blue-700 ${getVariantBg(variant)} focus:ring-blue-600 dark:text-blue-500`
    case 'ghost':
      return `border-transparent ${getVariantBg(variant)} focus:ring-blue-600  text-gray-700 dark:text-gray-200`
    case 'outline':
      return `border-gray-300 text-gray-700 dark:text-gray-200 dark:border-dark-400 ${getVariantBg(
        variant,
      )} focus:ring-blue-500`
    case 'danger-outline':
      return `border-red-700 text-red-700 dark:text-red-300 dark:border-red-400 ${getVariantBg(
        variant,
      )} focus:ring-blue-500 `
    case 'link':
      return `border-none ${getVariantBg(variant)}  hover:underline text-blue-700 dark:text-blue-500`
    case 'muted-link':
      return `border-none ${getVariantBg(variant)}  hover:underline text-gray-700 dark:text-gray-500`
    case 'danger-link':
      return `border-none ${getVariantBg(variant)}  hover:underline text-red-700 dark:text-red-400`
    case 'fill':
      return ` border-none w-full h-full ${getVariantBg(variant)}`
    case 'light':
      return ` border-none text-white ${getVariantBg(variant)}`
    case 'round':
      return `border-transparent rounded-full dark:text-white ${getVariantBg(variant)}`
    case 'round-indigo':
      return `border-transparent rounded-full text-white ${getVariantBg(variant)}`
    case 'unstyled':
      return ''
    default:
      return `border-gray-300 text-gray-700 dark:border-dark-400 dark:text-gray-200 ${getVariantBg(
        variant,
      )} focus:ring-blue-600`
  }
}

const baseClasses = (variant: ButtonVariant) => {
  switch (variant) {
    case 'unstyled':
      return ''
    default:
      return 'relative inline-flex border font-medium transition duration-100 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:z-10'
  }
}

const iconSizes: Record<ButtonSize, string> = {
  xxs: 'h-3 w-3',
  xs: 'h-4 w-4',
  sm: 'h-4 w-4',
  md: 'h-5 w-5',
  lg: 'h-5 w-5',
  xl: 'h-5 w-5',
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const analytics = useAnalytics()
  const {
    block,
    children,
    className,
    label,
    icon: Icon,
    rightIcon: RightIcon,
    type = 'button',
    wrap = false,
    variant,
    loading,
    disabled,
    onClick,
    ...rest
  } = props
  const iconSize = props.size || 'md'
  const sizeClasses = getSizeClasses(props.size, props.variant)
  const variantClasses = getVariantClasses(props.variant)

  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      const label: string | undefined = typeof children === 'string' ? children : rest['aria-label']
      if (label) {
        analytics?.track('Button - Click', {
          label,
        })
      }

      onClick(e)
    }
  }

  return (
    <button
      {...rest}
      className={twMerge(
        baseClasses(variant),
        sizeClasses,
        variantClasses,
        (disabled || loading) && 'pointer-events-none cursor-not-allowed',
        disabled && 'opacity-50',
        block ? 'w-full justify-center' : 'items-center',
        wrap ? 'whitespace-pre-wrap' : 'whitespace-nowrap',
        className,
      )}
      disabled={loading || disabled}
      type={type}
      onClick={onButtonClick}
      ref={ref}
    >
      {Icon && <Icon className={twMerge(iconSizes[iconSize], '-ml-1 mr-2', disabled && 'opacity-70')} />}
      {label && (
        <Badge rounded="md" padding="xs" size="xs" className="-ml-2 mr-2">
          {label}
        </Badge>
      )}
      {children}
      {RightIcon && <RightIcon className={twMerge('-mr-1 ml-2', iconSizes[iconSize])} />}
      {loading && (
        <div className="absolute inset-0 inline-flex h-full w-full items-center justify-center text-center">
          <div
            className={classNames([
              'disabled absolute inset-0 z-0 h-full w-full ',
              getVariantBg(variant, loading),
              getVariantClasses(variant),
            ])}
          />
          <ButtonSpinner variant={variant} />
        </div>
      )}
    </button>
  )
})

function ButtonSpinner({ variant }: { variant: ButtonVariant }) {
  return (
    <div className="z-10">
      <SpinnerCircularFixed
        color={variant === 'danger' ? red['600'] : blue['600']}
        secondaryColor={gray['100']}
        size={24}
        thickness={150}
      />
    </div>
  )
}

Button.displayName = 'Button'
