import { RequireRole } from 'components/RequireRole/RequireRole'
import { RemoteConfig, useRemoteConfig } from 'hooks/useRemoteConfig'
import { useTranslation } from 'react-i18next'
import { HiOutlineChat, HiOutlineMail, HiOutlineSupport } from 'react-icons/hi'
import { Nav } from './Nav'
import { NavGroup } from './NavGroup'
import { NavLink } from './NavLink'

export function OtherManagerNavLinks() {
  const remoteConfig: RemoteConfig = useRemoteConfig()
  const { t } = useTranslation()

  return (
    <Nav>
      <NavGroup name={t('Other')}>
        {remoteConfig?.EnableChat && (
          <NavLink href="/network/chat" icon={HiOutlineChat}>
            Chat
          </NavLink>
        )}
        <RequireRole allow="operations" deny="read-only">
          <NavLink href="/network/accounts/invites" icon={HiOutlineMail}>
            <span>{t('Invitations')}</span>
          </NavLink>
        </RequireRole>
        <NavLink href="https://help.complicore.co" icon={HiOutlineSupport}>
          {t('help')}
        </NavLink>
      </NavGroup>
    </Nav>
  )
}
