import { RequireFlag } from 'components/RequireFlag'
import { RequireRole } from 'components/RequireRole/RequireRole'
import { RemoteConfig, useRemoteConfig } from 'hooks/useRemoteConfig'
import { useSidebarContext } from 'hooks/useSidebarContext'
import { useTranslation } from 'react-i18next'
import { GrOverview } from 'react-icons/gr'
import {
  HiOutlineBell,
  HiOutlineClipboardCheck,
  HiOutlineClipboardList,
  HiOutlineDatabase,
  HiOutlineDocumentReport,
  HiOutlineExclamation,
  HiOutlineLocationMarker,
  HiOutlineMap,
  HiOutlineOfficeBuilding,
  HiOutlineTrendingUp,
  HiOutlineUsers,
  HiOutlineViewGrid,
} from 'react-icons/hi'
import {
  HiOutlineCalendar,
  HiOutlineClipboardDocumentList,
  HiOutlineCog6Tooth,
  HiOutlineDocumentCheck,
  HiOutlineNoSymbol,
} from 'react-icons/hi2'
import { RiTaxiLine, RiTaxiWifiLine } from 'react-icons/ri'
import { Nav } from './Nav'
import { NavGroup } from './NavGroup'
import { NavLink } from './NavLink'

export function ManagerNav() {
  const { stats } = useSidebarContext()
  const remoteConfig: RemoteConfig = useRemoteConfig()
  const { t } = useTranslation()

  return (
    <Nav>
      <NavLink href="/network/dashboard" icon={HiOutlineViewGrid}>
        {t('dashboard')}
      </NavLink>
      <RequireFlag flag="platform_announcements">
        <NavLink href="/announcements" icon={HiOutlineBell}>
          Announcements
        </NavLink>
      </RequireFlag>
      <NavGroup name={t('network')}>
        <NavLink href="/network/providers" icon={HiOutlineOfficeBuilding} stat={stats?.providers}>
          {t('providers')}
        </NavLink>
        <NavLink href="/network/drivers" icon={HiOutlineUsers} stat={stats?.drivers}>
          {t('drivers')}
        </NavLink>
        <NavLink href="/network/vehicles" icon={RiTaxiLine} stat={stats?.vehicles}>
          {t('vehicles')}
        </NavLink>
      </NavGroup>
      <RequireRole deny="operations">
        <NavGroup name={t('credentialing')}>
          <RequireRole allow="credentialing-director">
            <NavLink href="/network/directors/dashboard" icon={HiOutlineDocumentCheck}>
              {"Director's Dashboard"}
            </NavLink>
          </RequireRole>
          <RequireRole deny={['specialist', 'read-only']} allow={'manager'}>
            <NavLink href="/network/productivity" icon={HiOutlineDocumentReport}>
              Productivity
            </NavLink>
          </RequireRole>
          <NavLink href="/network/authorizations" icon={HiOutlineClipboardCheck}>
            Authorizations
          </NavLink>
          <RequireRole allow="manager" deny="read-only">
            <NavLink href="/network/authorizations/webhook-failures" icon={HiOutlineExclamation}>
              Integration Issues
            </NavLink>
          </RequireRole>
          <RequireFlag flag="exclusion_lists">
            <RequireRole allow="manager" deny="read-only">
              <NavLink href="/network/exclusions" icon={HiOutlineNoSymbol}>
                Exclusion List
              </NavLink>
            </RequireRole>
          </RequireFlag>
        </NavGroup>
      </RequireRole>
      <RequireRole allow="operations" deny="read-only">
        <NavGroup name="Operations">
          <RequireRole allow="manage-service-area">
            <NavLink href="/network/area-of-service" icon={HiOutlineMap}>
              Service Area
            </NavLink>
          </RequireRole>
          <RequireRole allow="manage-trip-requests">
            {remoteConfig?.ShowSameDayTripsOptIn && (
              <NavLink href="/network/trip-requests" icon={HiOutlineLocationMarker}>
                Trip Requests
              </NavLink>
            )}
            {remoteConfig?.ShowSameDayTripsOptIn && (
              <NavLink href="/network/trip-requests/open-trips" icon={HiOutlineClipboardList}>
                Open Trips
              </NavLink>
            )}
          </RequireRole>
        </NavGroup>
      </RequireRole>
      <RequireRole allow={['inspector', 'manager']} deny="operations">
        <NavGroup name="Inspections">
          <RequireRole allow="inspection-manager">
            <NavLink href="/inspector/dashboard" icon={HiOutlineViewGrid}>
              Dashboard
            </NavLink>
          </RequireRole>
          <RequireRole allow="inspections-overview">
            <NavLink href="/network/inspections/overview" icon={GrOverview}>
              Overview
            </NavLink>
          </RequireRole>
          <RequireRole allow="inspector">
            <NavLink href="/inspector/inspections" icon={HiOutlineCalendar}>
              Assignments
            </NavLink>
            <NavLink href="/inspector/in-person" icon={RiTaxiWifiLine} level={2}>
              In-Person Overview
            </NavLink>
          </RequireRole>
          <NavLink href="/network/inspections" icon={HiOutlineClipboardDocumentList}>
            Results
          </NavLink>
          <RequireRole allow="inspection-manager">
            <NavLink href="/inspector/inspectors" icon={HiOutlineUsers}>
              Inspectors
            </NavLink>
          </RequireRole>
          <RequireRole allow="inspector">
            <NavLink href="/inspector/settings" icon={HiOutlineCog6Tooth}>
              Settings
            </NavLink>
          </RequireRole>
        </NavGroup>
      </RequireRole>
      <RequireRole allow={['write-performance', 'superadmin']}>
        {remoteConfig?.EnableProviderPerformance && (
          <NavGroup name={t('Performance')}>
            <NavLink href="/network/performance" icon={HiOutlineTrendingUp}>
              Performance
            </NavLink>
            <NavLink href="/network/performance/data" icon={HiOutlineDatabase}>
              Data
            </NavLink>
          </NavGroup>
        )}
      </RequireRole>
    </Nav>
  )
}
