import { InspectorNav } from './InspectorNav'
import { ManagerNav } from './ManagerNav'
import { NavGroup } from './NavGroup'

export function OperationsNav() {
  return (
    <>
      <ManagerNav />

      <NavGroup name="Inspections">
        <InspectorNav />
      </NavGroup>
    </>
  )
}
