import { t } from 'i18next'
import { HiViewGrid } from 'react-icons/hi'
import { HiBuildingOffice2, HiClipboardDocumentList } from 'react-icons/hi2'
import { Nav } from './Nav'
import { NavGroup } from './NavGroup'
import { NavLink } from './NavLink'

export function DiversityNav() {
  return (
    <Nav>
      <NavGroup name={t('Diversity')}>
        <NavLink href="/network/diversity" icon={HiViewGrid}>
          Dashboard
        </NavLink>
        <NavLink href="/network/diversity/providers" icon={HiBuildingOffice2}>
          Providers
        </NavLink>
        <NavLink href="/network/diversity/submissions" icon={HiClipboardDocumentList}>
          Submissions
        </NavLink>
      </NavGroup>
    </Nav>
  )
}
